.nixa{
    height: 100px;
    width: 100px;
}

.experienceLogo{
    height: 400px;
    width: 600px;
}

