.animate{
    /* box-shadow: 0px 5px 15px 0px black; */
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;
}

@keyframes float{
    0%{
        /* box-shadow: 0px 5px 15px 0px black; */
        transform: translateY(0px);
    }
    50%{
        /* box-shadow: 0px 5px 15px 0px black; */
        transform: translateY(-20px);
    }
   
    
    60%{
        /* box-shadow: 0px 5px 15px 0px black; */
        transform: translateY(-20px);
    }
    
 


}