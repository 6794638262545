
.btn:hover {
	background-color:gray;
    color: white;
}
.myButton:active {
	position:relative;
	top:1px;
}


.aboutImg{
    height: 100px;
    width: 500px;
}



        