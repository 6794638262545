.blur{
    filter: blur(4px);
    z-index: 1;
}
.myColor{
    background-color: rgb(7, 2, 17);
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
}

ul{
    list-style: none;
}
ul li{
    display: inline-block;
    margin: 0px 0;
}


ul  a{
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 2px;
    color: #fff;
    margin: 0 10px;
    padding: 1% 1%;
    position: relative;
}
ul  a::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background: #591c8b;
    z-index: -1;
    box-shadow: 0 0 10px #7a3a86,
                0 0 20px #7a3a86,
                0 0 40px #7a3a86,
                0 0 80px #7a3a86,
                0 0 100px #7a3a86;
    transition: 0.25s;
    border-radius: 5px;
}
ul  a:hover::before{
    width: 100%;
}

.persoColorFrench{
    background-color:rgb(150, 145, 221) ;
    border: none;
    color: white;
}

.persoColorEnglish{
    background-color:rgb(255, 255, 255) ;
    border: none;
    color: black;
}

.bg-lang{
    background-color:rgb(31, 21, 172) ;

}


/* 
.positionLeft{
    margin-left: 400px;
    margin-top: 0;
}
.hamburger span {
    display: block;
    width: 33px;
    height:5px;
    margin-bottom: 5px;
    position: relative;

    background-color: white;
    border-radius:6px;
    z-index: 1;

    transform-origin: 0  0;
    transition: 0.4s;
}

.hamburger:hover span:nth-child(2){
    transform: translateX(10px);
    background-color: white;
}

.menu{
    display: none;
} */
