.get_in_touch{
   max-width: 600px;
   margin: 50px auto;
   position: relative;
   box-shadow: 0 10px 30px 0px rgba(0,0,0,0.1);
   padding:30px; 
}

.get_in_touch .title{
    font-size:3em;
    line-height: 48px;
    padding-bottom:10px;
    /* color: #5543ca; */
    color: linear-gradient(to right, #f4524d 0%, #5543ca 100%);
}

.contact-form .form-field{
    position: relative;
    margin: 32px 0;
}

.contact-form .input-text{
    display: block;
    width: 100%;
    height: 100%;
    border-width: 0 0 2px 0;
    border-color: #5543ca;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400 ;
}

.contact-form .input-text:focus{
    outline:none;
}

.contact-form .label{
    position:absolute;
    left:10px;
    bottom:40px;
    font-size:18px;
    line-height:26px;
    font-weight: 400;
    color:gray;
    cursor: text;
    transition: transform 0.2s ease-in-out;
}

.contact-form .submit-btn{
    display: inline-block;
    background-image: linear-gradient(125deg,#a72879, #064497);
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 16px;
    padding: 8px 18px;
    width:200px;
    cursor:pointer;
    border: none;
    border-radius: 5px;
}
