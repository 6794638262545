.project{
    background-color: #191919;
}
img.project__img {
    border-radius: 10px;
    height: 350px;
    object-fit: cover;
    transition: .4s;
    opacity: .7;
    position: relative;
}
img.project__img::after{
    content: '';
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 1;
    opacity: 1;
}
.mask__effect{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f9004d;
    border-radius: 10px;
    transition: .4s;
    opacity: .3;
    display: none;
}
.project__box:hover .mask__effect{
    /* display: inline; */
    transform: scale(1.1);
 }
 .project__box:hover img.project__img{
     transform: scale(1.1);
 }
 
/* RESPONSIVE */
@media only screen and (max-width: 1024px){
h5.project__text {
    font-weight: 400;
    font-size: 20px;
    padding-bottom: 20px;
}
h4.project__text {
    font-size: 18px;
}
}

.bg-image{
    background: url('../images/project1.png')  no-repeat;
    /* height: 100px; */
    width:100%;
}
.top-right-forest {
    position: absolute;
    top: 8px;
    left: 145px;
}

.top-right-manage {
    position: absolute;
    top: 8px;
    left: 145px;
}



.top-right-loopstudio{
    position: absolute;
    top: 8px;
    left: 110px;
}

.bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }


  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .lesssize{
      font-size: 20px;
  }

  @media (max-width:1200px){
    .top-right-forest {
        position: absolute;
        top: 8px;
        left: 155px;
    }
    
    .top-right-manage {
        position: absolute;
        top: 8px;
        left: 145px;
    }
    
    .top-right-loopstudio{
        position: absolute;
        top: 15px;
        left: 125px;
    }


    
    .lesssize{
        font-size: 15px;
    }
  }
 